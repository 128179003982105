import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { gsap } from "gsap";

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100svh;
  justify-content: center;
  padding: 0 20px;
  position: relative;
  width: 100%;
  z-index: 2;
`;

const Button = styled.button`
  background-color: #ddd;
  border: none;
  border-radius: 0;
  color: black !important;
  font-size: 11.5px;
  padding-top: 1.5px;
  cursor: pointer;
  font-weight: 700;
  height: 46px;
  transition: background-color 125ms ease-in-out;

  &[disabled],
  &[disabled]:hover {
    background-color: #808080;
    cursor: disabled;
  }

  &:hover {
    background-color: white;
  }

  @media only screen and (min-width: 500px) {
    font-size: 17px;
    padding-top: 2.5px;
    height: 60px;
  }
`;

const Input = styled.input`
  background: black;
  border-radius: 0;
  border: none;
  color: #808080;
  font-size: 12px;
  height: 48px;
  margin-bottom: 6px;
  outline: 1px solid #505050;
  padding-top: 2px;
  text-align: center;

  &:focus {
    color: white;
    outline: 1px solid white;
  }

  &[disabled],
  &[disabled]:hover {
    cursor: disabled;
    outline-color: white;
  }

  @media only screen and (min-width: 500px) {
    font-size: 18px;
    height: 64px;
    margin-bottom: 8.8937px;
    padding-top: 5px;
  }
`;

const Form = styled.form`
  max-width: 300px;
  display: flex;
  flex-direction: column;
  width: 100%;

  @media only screen and (min-width: 500px) {
    max-width: 400px;
  }

  ${Button},
  ${Input} {
    font-family: "Atlas Grotesk LC", Helvetica, sans-serif;
    letter-spacing: 0.12em;
    text-transform: uppercase;
  }
`;

const SignIn = ({ passwordFails, setPassword, signedIn }) => {
  const [formPassword, setFormPassword] = useState("");
  const [processing, setProcessing] = useState(false);
  const formRef = useRef();
  const passwordFieldRef = useRef();

  const signIn = e => {
    e.preventDefault();
    setProcessing(true);

    setPassword(formPassword);
  };

  useEffect(() => {
    if (passwordFails === 0) {
      return;
    }

    gsap
      .timeline({
        defaults: {
          duration: 2,
          ease: "power3.out",
        },
      })
      .fromTo(
        passwordFieldRef.current,
        {
          outlineColor: "red",
        },
        {
          outlineColor: "#505050",
        }
      );

    setProcessing(false);
    setFormPassword("");
  }, [passwordFails]);

  useEffect(() => {
    if (!signedIn) {
      return;
    }

    gsap.timeline().set(
      formRef.current,
      {
        display: "none",
      },
      "1"
    );
  }, [signedIn]);

  return (
    <Container>
      <Form autoComplete="off" onSubmit={signIn} ref={formRef}>
        <Input
          autoFocus
          autoComplete="off"
          data-1p-ignore
          disabled={processing}
          type="password"
          ref={passwordFieldRef}
          placeholder="Enter password"
          value={formPassword}
          onChange={e => setFormPassword(e.target.value)}
        />
        <Button disabled={processing} type="submit">
          {processing ? "Processing..." : "Login"}
        </Button>
      </Form>
    </Container>
  );
};

export default SignIn;
