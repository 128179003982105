/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { gsap } from "gsap";

const Container = styled.div`
  width: 100%;
  height: 100svh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;

  video {
    max-width: 100%;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
  }
`;

const VideoPlayer = ({ password, setSignedIn, setPasswordFails, passwordFails }) => {
  const videoRef = useRef();
  const playButtonRef = useRef();

  const onLoadedVideoData = evt => {
    console.log(evt);
  };

  const playVideo = () => {
    var playPromise = videoRef.current.play();

    // In browsers that don’t yet support this functionality,
    // playPromise won’t be defined.
    if (playPromise !== undefined) {
      playPromise
        .then(function () {
          setSignedIn(true);
        })
        .catch(function (error) {
          console.log(error);
          setPasswordFails(passwordFails + 1);
        });
    }

    gsap
      .timeline({
        defaults: {
          duration: 1.25,
          ease: "power3.out",
        },
      })
      .to(playButtonRef.current, {
        opacity: 0,
      })
      .set(playButtonRef.current, {
        display: "none",
      });
  };

  useEffect(() => {
    if (!password) {
      return;
    }

    playVideo();
  }, [password]);

  return (
    <Container>
      {password && (
        <video
          controls
          controlsList="nodownload"
          height="1080"
          onLoadedData={onLoadedVideoData}
          playsInline
          preload="metadata"
          ref={videoRef}
          src={`/video/${password}.mp4`}
          type="video/mp4"
          width="1920"
        />
      )}
    </Container>
  );
};

export default VideoPlayer;
